// Libraries CSS Files
import '@bootstrap/dist/css/bootstrap.css'
import '../lib/font-awesome/css/font-awesome.min.css'
import '../lib/animate/animate.min.css'
import '../lib/ionicons/css/ionicons.min.css'
import '../css/font-icon.css'
import '../css/style.css'

// Libraries JS Files
import * as bootstrap from 'bootstrap'
import jQuery from 'jquery'
import WOW from 'wow.js'

window.jQuery = jQuery
window.jquery = jQuery
window.$ = jQuery

jQuery(() => {
    $(window).on('scroll', () => {
        if ($(window).scrollTop() > 150) {
            $('.back-to-top').fadeIn('slow')
        } else {
            $('.back-to-top').fadeOut('slow')
        }
    })

    $('.back-to-top').on('click', () => {
        $('html, body').animate({ scrollTop: 0 }, 800)
        return false
    })

    $('#topbar').sticky({
        topSpacing: 0,
        zIndex: '51',
    })

    $('#header').sticky({
        topSpacing: '65px',
        zIndex: '55',
    })

    const wow = new WOW()
    wow.init()

    $('.nav-menu').superfish({
        animation: {
            opacity: 'show',
        },
        speed: 400,
    })

    if ($('#nav-menu-container').length) {
        let $mobile_nav = $('#nav-menu-container').clone().prop({
            id: 'mobile-nav',
        })
        $mobile_nav.find('> ul').attr({
            class: '',
            id: '',
        })
        $('body').append($mobile_nav)
        $('body').prepend(
            '<button type="button" id="mobile-nav-toggle"><i class="fa fa-bars"></i></button>',
        )
        $('body').append('<div id="mobile-body-overly"></div>')
        $('#mobile-nav')
            .find('.menu-has-children')
            .prepend('<i class="fa fa-chevron-down"></i>')

        $(document).on('click', '.menu-has-children i', function (e) {
            $(this).next().toggleClass('menu-item-active')
            $(this).nextAll('ul').eq(0).slideToggle()
            $(this).toggleClass('fa-chevron-up fa-chevron-down')
        })

        $(document).on('click', '#mobile-nav-toggle', (e) => {
            $('body').toggleClass('mobile-nav-active')
            $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars')
            $('#mobile-body-overly').toggle()
        })

        $(document).on('click', (e) => {
            let container = $('#mobile-nav, #mobile-nav-toggle')
            if (
                !container.is(e.target) &&
                container.has(e.target).length === 0
            ) {
                if ($('body').hasClass('mobile-nav-active')) {
                    $('body').removeClass('mobile-nav-active')
                    $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars')
                    $('#mobile-body-overly').fadeOut()
                }
            }
        })
    } else if ($('#mobile-nav, #mobile-nav-toggle').length) {
        $('#mobile-nav, #mobile-nav-toggle').hide()
    }

    $('.nav-menu a, #mobile-nav a, .scrollto').on('click', function () {
        if (
            window.location.pathname.replace(/^\//, '') ===
                this.pathname.replace(/^\//, '') &&
            window.location.hostname === this.hostname
        ) {
            let target = $(this.hash)
            if (target.length) {
                let top_space = 0

                if ($('#header').length) {
                    top_space = $('#header').outerHeight()

                    if (!$('#header').hasClass('header-fixed')) {
                        top_space = top_space - 20
                    }
                }

                $('html, body').animate(
                    {
                        scrollTop: target.offset().top - top_space,
                    },
                    1500,
                    'easeInOutExpo',
                )

                if ($(this).parents('.nav-menu').length) {
                    $('.nav-menu .menu-active').removeClass('menu-active')
                    $(this).closest('li').addClass('menu-active')
                }

                if ($('body').hasClass('mobile-nav-active')) {
                    $('body').removeClass('mobile-nav-active')
                    $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars')
                    $('#mobile-body-overly').fadeOut()
                }
                return false
            }
        }
    })
})
